var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"track-order-container"},[_c('div',[_c('b-row',[_c('b-col',{staticClass:"heading-with-text"},[_c('h1',{staticClass:"title title-small text-uppercase text-center"},[_vm._v(_vm._s(_vm.$t('checkOrderStatus')))]),_c('form',{staticClass:"account-content__body container-fluid",on:{"submit":_vm.handleSubmit}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"groupOrderNumber","label-for":"order-number"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"order-number","type":"text","autofocus":true,"placeholder":_vm.$t('Order'),"validations":[
                    {
                      condition: _vm.errors.has('order-number'),
                      text: _vm.errors.first('order-number')
                    }
                  ]},model:{value:(_vm.form.orderId),callback:function ($$v) {_vm.$set(_vm.form, "orderId", $$v)},expression:"form.orderId"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"id":"groupEmail","label-for":"email"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"track-email","type":"email","placeholder":_vm.$t('email'),"validations":[
                    {
                      condition: _vm.errors.has('track-email'),
                      text: _vm.errors.first('track-email')
                    }
                  ]},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.serverValidation),expression:"serverValidation"}],staticClass:"form-errors is-invalid mb-3"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.serverValidation)}})]),_c('div',{staticClass:"block-submit d-md-flex"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('Track Order')))])])],1)])],1),(_vm.detailOrder)?_c('b-row',{staticClass:"mt-4_1 detail-order"},[_c('b-col',{staticClass:"mx-auto",attrs:{"md":"10"}},[_c('h1',{staticClass:"title title-small text-uppercase text-center"},[_vm._v(_vm._s(_vm.$t('order.my')))]),_c('p',{staticClass:"subtitle text-center"},[_vm._v("\n          "+_vm._s(_vm.$t('Order'))+": "),_c('strong',[_vm._v("#"+_vm._s(_vm.detailOrder.entity_id))])]),_c('div',{staticClass:"account-content__body container-fluid"},[_c('b-card-group',{attrs:{"deck":""}},[_c('b-card',{staticClass:"text-center"},[_c('b-card-text',{staticClass:"d-flex align-items-center justify-content-center"},[_c('div',[_vm._v("\n                  "+_vm._s(_vm.$t('order.status'))+":"),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.$t(_vm.detailOrder.status)))])])])],1),_c('b-card',{staticClass:"text-center"},[_c('b-card-text',{staticClass:"d-flex align-items-center justify-content-center"},[_c('div',[_vm._v("\n                  "+_vm._s(_vm.$t('carrierName'))+": "),_c('strong',[_vm._v(_vm._s(_vm.carrierName))]),_c('br'),_vm._v("\n                  "+_vm._s(_vm.$t('trackingNumber'))+": "),_c('strong',[_vm._v(_vm._s(_vm.trackingNumber))]),_c('br'),_c('a',{attrs:{"href":_vm.trackingLink}},[_vm._v(_vm._s(_vm.$t('followYourOrder')))])])])],1)],1)],1)])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }